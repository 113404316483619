import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 423.000000 258.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,258.000000) scale(0.100000,-0.100000)">


<path d="M525 1980 c-46 -23 -83 -72 -96 -127 -11 -50 -11 -776 0 -826 14 -59
51 -105 107 -133 l50 -25 315 3 c245 2 313 6 312 16 -2 6 2 12 7 12 6 0 10 -7
10 -15 0 -13 143 -15 1228 -15 l1227 1 45 23 c31 15 55 38 75 71 l30 48 0 426
0 426 -27 47 c-19 32 -41 52 -71 67 l-43 21 -1565 0 c-1542 0 -1565 0 -1604
-20z m381 -32 c3 -4 -1 -5 -10 -4 -8 1 -18 5 -22 8 -3 4 1 5 10 4 8 -1 18 -5
22 -8z m-49 -47 c-8 -9 -12 -10 -15 -2 -2 6 -8 9 -12 6 -5 -3 -11 -2 -14 3 -2
4 8 8 24 7 23 0 26 -3 17 -14z m43 15 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12
-4 4 13 16 21 21 21 13z m22 -18 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3
-1 -11 -7 -17z m40 0 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7
-17z m58 18 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2
10 -4z m-213 -43 c2 -5 -3 -16 -12 -23 -8 -7 -15 -9 -16 -4 0 5 -2 16 -4 25
-3 18 26 20 32 2z m233 7 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m-200 -20 c-6 -11 -15 -20 -21 -20 -6 0 -6 7 1 20 6 11 15
20 21 20 6 0 6 -7 -1 -20z m39 -2 c-9 -16 -14 -19 -17 -9 -2 7 2 17 9 22 20
13 21 11 8 -13z m42 -11 c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3 -7 -1 -17 -7
-23z m55 6 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m-206
-18 c0 -1 -8 -9 -18 -18 -18 -16 -19 -16 -16 5 1 13 3 24 3 25 1 3 31 -8 31
-12z m310 5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-142 -33 c-7 -14 -13 -17 -16 -9 -6 19 6 44 18 37 7 -4 6 -14 -2 -28z
m72 18 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
-15z m-230 -20 c-7 -9 -15 -13 -18 -10 -3 2 1 11 8 20 7 9 15 13 18 10 3 -2
-1 -11 -8 -20z m50 6 c-5 -11 -15 -23 -20 -26 -14 -9 -12 19 2 33 19 19 30 14
18 -7z m60 12 c0 -5 -4 -15 -10 -23 -11 -18 -25 -7 -17 15 6 15 27 21 27 8z
m240 -24 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m-424 -27
c-18 -16 -18 -16 -6 6 6 13 14 21 18 18 3 -4 -2 -14 -12 -24z m342 11 c-4 -22
-22 -20 -26 1 -2 10 3 16 13 16 10 0 15 -7 13 -17z m-312 -17 c-11 -18 -12
-26 -3 -29 9 -3 9 -9 0 -30 -7 -15 -17 -27 -23 -27 -13 0 -13 27 1 35 6 4 8
14 5 22 -7 17 10 53 26 53 6 0 4 -10 -6 -24z m164 -1 c0 -16 -6 -25 -15 -25
-18 0 -20 21 -3 38 17 17 18 16 18 -13z m70 5 c0 -11 -7 -20 -15 -20 -15 0
-21 21 -8 33 12 13 23 7 23 -13z m-190 -9 c-12 -23 -30 -36 -30 -22 0 21 13
41 26 41 11 0 12 -4 4 -19z m60 5 c0 -16 -28 -42 -36 -33 -8 7 14 47 26 47 6
0 10 -6 10 -14z m340 -1 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10
15 6 0 10 -7 10 -15z m-85 -25 c0 -7 -6 -15 -12 -17 -8 -3 -13 4 -13 17 0 13
5 20 13 18 6 -3 12 -11 12 -18z m-400 -14 c-8 -29 -11 -96 -4 -96 3 0 11 11
17 25 16 35 35 32 30 -5 -3 -19 0 -30 8 -30 17 0 23 -21 13 -48 -12 -31 -34
-23 -34 11 1 38 -22 42 -29 5 -7 -37 -32 -32 -28 6 1 14 1 26 -2 26 -7 0 -46
-80 -45 -93 0 -7 5 0 11 16 13 37 30 34 26 -5 -2 -20 -8 -32 -15 -30 -8 1 -13
-9 -13 -28 0 -38 18 -40 22 -2 2 15 7 25 13 22 5 -3 12 9 15 25 7 39 34 40 38
2 3 -22 -1 -30 -17 -34 -12 -3 -21 -15 -23 -32 -2 -14 -8 -25 -14 -24 -6 1
-11 -10 -11 -24 0 -15 -5 -29 -10 -30 -12 -4 -5 -51 8 -59 5 -3 6 9 3 26 -5
23 -3 30 10 30 11 0 16 9 16 30 0 19 5 30 14 30 8 0 16 12 18 27 4 33 23 41
38 14 13 -24 -4 -66 -23 -58 -8 3 -12 -7 -12 -27 0 -22 -5 -31 -16 -30 -11 1
-14 -6 -11 -27 3 -19 0 -29 -8 -29 -8 0 -10 -9 -6 -24 3 -14 2 -27 -3 -30 -10
-7 -3 -46 9 -46 4 0 5 12 2 27 -4 21 -2 25 12 20 14 -6 15 -3 5 14 -6 11 -8
28 -5 36 6 14 9 14 26 -2 22 -20 27 -65 7 -65 -13 0 -15 -67 -2 -117 l7 -28
-21 27 c-12 14 -21 33 -21 42 0 9 -5 16 -11 16 -5 0 -7 5 -4 10 3 6 2 10 -3
10 -9 0 -15 12 -37 75 -6 16 -5 17 4 5 13 -18 13 -3 1 25 -6 14 -9 16 -11 5
-1 -8 -6 8 -11 35 -14 71 -14 221 0 237 6 8 9 25 6 39 -4 21 -3 22 5 4 8 -17
10 -13 11 20 2 22 2 43 1 48 0 4 6 7 14 7 9 0 15 6 14 13 -3 25 1 34 17 34 11
0 14 -6 9 -21z m315 -7 c0 -10 -7 -19 -15 -19 -15 0 -21 31 -9 43 11 10 24 -3
24 -24z m-80 -3 c0 -28 -17 -43 -32 -28 -7 7 -6 17 1 31 16 28 31 27 31 -3z
m315 15 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-455 -20 c0
-23 -25 -46 -35 -32 -7 13 12 51 25 51 6 0 10 -8 10 -19z m70 8 c0 -17 -20
-49 -30 -49 -11 0 -14 43 -3 53 10 11 33 8 33 -4z m296 -10 c10 -17 -13 -36
-27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z m-96 -27 c0 -10 -3 -22 -7 -25
-10 -11 -32 13 -26 29 8 20 33 17 33 -4z m255 8 c3 -5 1 -10 -4 -10 -6 0 -11
5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-350 -30 c0 -27 -22 -39 -31 -16 -8
22 3 49 19 43 6 -2 12 -14 12 -27z m-155 -9 c0 -18 -18 -41 -32 -41 -12 0 -9
45 4 58 14 14 28 6 28 -17z m75 19 c10 -16 -5 -50 -21 -50 -20 0 -29 27 -14
45 14 17 26 19 35 5z m335 -15 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7
15 15 15 8 0 15 -7 15 -15z m-100 -25 c0 -21 -24 -28 -35 -10 -8 14 3 30 21
30 8 0 14 -9 14 -20z m-96 -7 c8 -19 -17 -48 -33 -39 -12 8 -15 39 -4 49 12
12 31 7 37 -10z m-96 -17 c5 -22 -16 -46 -34 -39 -17 6 -18 35 -2 51 16 16 32
10 36 -12z m362 9 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0
15 -7 15 -15z m-450 -24 c0 -32 -23 -47 -40 -26 -8 10 -8 19 0 34 16 31 40 26
40 -8z m347 3 c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z
m-107 -20 c11 -12 11 -18 -2 -31 -19 -18 -38 -10 -38 16 0 33 20 40 40 15z
m280 1 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
-15z m-482 -17 c16 -16 15 -34 -4 -53 -19 -18 -44 -4 -44 24 0 17 18 41 30 41
3 0 11 -5 18 -12z m100 -15 c-3 -31 -34 -44 -44 -19 -9 24 3 46 26 46 17 0 21
-5 18 -27z m-183 6 c12 -19 -4 -61 -23 -57 -30 6 -28 68 2 68 8 0 18 -5 21
-11z m465 -10 c0 -25 -33 -22 -38 4 -2 12 3 17 17 17 15 0 21 -6 21 -21z m660
-118 l0 -129 -75 0 c-68 -1 -76 2 -95 25 -28 35 -27 102 2 138 19 24 26 27 65
22 l43 -5 0 39 c0 37 2 39 30 39 l30 0 0 -129z m880 91 l0 -38 40 4 c49 5 67
-8 80 -60 13 -46 -1 -103 -28 -117 -10 -5 -50 -9 -90 -9 l-72 0 0 129 0 129
35 0 c34 0 35 -1 35 -38z m-1652 0 c3 -16 -2 -22 -17 -22 -24 0 -35 15 -27 36
9 23 40 13 44 -14z m292 24 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m-405 -46 c0 -16 -6 -26 -18 -28 -19 -4 -32 20 -23 44
9 25 41 13 41 -16z m295 4 c0 -14 -21 -19 -33 -7 -9 9 13 34 24 27 5 -3 9 -12
9 -20z m-395 -19 c0 -23 -5 -31 -22 -33 -26 -4 -41 31 -22 54 20 24 44 13 44
-21z m-100 -10 c0 -23 -4 -30 -20 -30 -21 0 -31 25 -21 51 11 28 41 12 41 -21z
m383 2 c4 -21 -27 -35 -38 -17 -12 19 0 42 19 38 9 -2 17 -11 19 -21z m922
-91 c0 -89 -3 -107 -21 -130 -17 -22 -28 -26 -73 -26 -40 0 -57 5 -70 19 -10
11 -15 25 -11 30 8 14 55 14 55 1 0 -5 11 -10 25 -10 14 0 25 2 25 4 0 2 2 10
5 18 4 10 -5 12 -39 10 -61 -5 -86 22 -86 95 0 78 18 92 115 92 l75 0 0 -103z
m160 79 c0 -18 -6 -24 -27 -27 -27 -3 -28 -5 -31 -70 -3 -68 -3 -68 -32 -68
l-29 0 -3 95 -3 94 63 0 c58 -1 62 -2 62 -24z m158 9 c32 -22 28 -44 -8 -44
-16 0 -30 5 -30 10 0 6 -12 10 -26 10 -36 0 -25 -23 14 -31 42 -9 82 -36 82
-56 0 -9 -10 -27 -22 -40 -30 -32 -118 -33 -148 -1 -27 30 -25 38 10 38 17 0
30 -4 30 -10 0 -5 16 -10 36 -10 48 0 40 21 -13 35 -51 13 -73 31 -73 61 0 31
36 54 85 54 22 0 51 -7 63 -16z m112 -42 c0 -69 18 -104 44 -83 12 10 16 31
16 77 l0 64 35 0 35 0 0 -95 0 -95 -30 0 c-16 0 -30 5 -30 10 0 7 -6 7 -19 0
-11 -5 -33 -10 -50 -10 -45 0 -61 30 -61 117 l0 73 30 0 c30 0 30 0 30 -58z
m630 38 c11 -11 20 -27 20 -35 0 -21 -49 -20 -65 2 -29 39 -69 -24 -46 -73 12
-27 39 -32 47 -9 8 20 64 20 64 0 0 -29 -42 -55 -87 -55 -61 0 -93 32 -93 93
0 67 28 97 91 97 36 0 54 -5 69 -20z m195 14 c8 -4 22 -19 30 -35 39 -74 -6
-149 -87 -149 -55 0 -84 20 -97 67 -12 41 5 94 36 112 20 11 92 15 118 5z
m125 -6 c0 -9 3 -9 12 0 15 15 70 16 86 0 8 -8 15 -8 26 1 8 7 30 11 48 9 43
-4 58 -34 58 -120 l0 -68 -30 0 -30 0 0 63 c0 69 -10 93 -34 84 -13 -5 -16
-21 -16 -77 l0 -70 -29 0 c-29 0 -29 0 -33 69 -4 75 -9 87 -33 78 -11 -5 -15
-23 -15 -77 l0 -70 -35 0 -35 0 0 95 0 95 30 0 c17 0 30 -5 30 -12z m-2412
-26 c4 -28 -24 -40 -45 -19 -14 13 -14 19 -3 32 18 22 44 15 48 -13z m292 13
c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m-405 -35 c0 -18
-6 -26 -23 -28 -24 -4 -38 18 -28 44 3 9 15 14 28 12 17 -2 23 -10 23 -28z
m-205 6 c6 -8 10 -25 8 -38 -4 -32 -42 -32 -46 0 -7 46 14 66 38 38z m106 -12
c8 -30 -6 -47 -32 -42 -35 6 -30 68 5 68 14 0 23 -9 27 -26z m402 9 c5 -17
-26 -29 -40 -15 -6 6 -7 15 -3 22 9 14 37 9 43 -7z m-123 -33 c0 -16 -6 -26
-18 -28 -19 -4 -32 20 -23 44 9 25 41 13 41 -16z m295 20 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-406 -32 c16 -26
-16 -57 -38 -38 -17 13 -21 42 -9 54 11 11 35 3 47 -16z m-116 0 c17 -17 15
-58 -3 -64 -21 -8 -45 14 -45 41 0 35 24 47 48 23z m415 5 c9 -9 -13 -34 -24
-27 -14 8 -11 34 4 34 8 0 17 -3 20 -7z m-595 -62 c-2 -9 -11 -17 -20 -19 -20
-4 -34 37 -23 65 6 16 8 16 26 -6 11 -13 18 -31 17 -40z m76 40 c31 -34 0 -81
-32 -49 -20 20 -14 68 8 68 4 0 15 -9 24 -19z m406 -6 c15 -18 5 -35 -20 -35
-10 0 -22 5 -25 10 -7 11 12 40 25 40 4 0 13 -7 20 -15z m-122 -17 c17 -17 15
-45 -4 -52 -18 -7 -44 10 -44 28 0 13 20 36 30 36 3 0 11 -5 18 -12z m304 -3
c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z
m1578 -20 c0 -21 -5 -25 -30 -25 -25 0 -30 4 -30 25 0 21 5 25 30 25 25 0 30
-4 30 -25z m-1992 3 c15 -15 16 -45 2 -53 -15 -9 -47 4 -54 22 -12 31 28 55
52 31z m-110 -10 c14 -14 16 -54 3 -62 -16 -10 -41 6 -47 30 -10 39 17 59 44
32z m412 -7 c0 -11 -4 -22 -8 -25 -14 -9 -35 12 -29 28 9 23 37 20 37 -3z
m-506 -7 c10 -27 7 -54 -7 -54 -20 0 -37 22 -37 47 0 27 34 33 44 7z m394 -28
c-2 -13 -10 -21 -23 -21 -23 0 -33 34 -14 45 20 13 40 -1 37 -24z m292 30 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-400
-55 c0 -23 -28 -35 -46 -20 -17 14 -18 46 -1 52 19 7 47 -12 47 -32z m290 14
c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z
m-402 -28 c4 -36 -23 -47 -49 -20 -27 26 -15 55 20 51 20 -2 27 -9 29 -31z
m-195 -4 c17 -39 44 -39 39 -2 -3 24 -1 29 16 29 11 0 25 -7 32 -15 16 -19 8
-50 -13 -49 -13 1 -15 -2 -6 -18 13 -25 4 -52 -16 -44 -12 4 -13 2 -2 -16 9
-16 9 -22 0 -25 -9 -3 -10 -10 -3 -24 7 -13 7 -19 1 -19 -6 0 -2 -16 9 -35 20
-36 20 -36 0 -25 -11 6 -24 19 -30 31 -6 11 -15 17 -20 14 -12 -7 -56 53 -64
88 -7 31 0 34 26 10 20 -18 25 -48 6 -37 -8 5 -8 2 1 -10 8 -9 24 -22 35 -28
20 -11 20 -10 4 9 -23 25 -23 37 -1 29 16 -6 16 -6 0 12 -21 23 -22 42 -2 42
13 0 14 4 5 20 -6 11 -17 20 -25 20 -18 0 -49 46 -40 60 12 19 36 10 48 -17z
m485 4 c4 -22 -27 -35 -38 -16 -10 15 7 42 24 36 6 -2 12 -11 14 -20z m-124
-3 c21 -8 21 -43 0 -51 -8 -3 -23 1 -31 10 -13 13 -14 19 -3 31 14 18 13 18
34 10z m296 -4 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-406 -16 c23 -9 21 -54 -3 -54 -24 0 -45 25 -37 45 6 16 16 18 40
9z m306 -20 c0 -8 -9 -14 -20 -14 -19 0 -27 19 -14 33 10 9 34 -4 34 -19z
m-570 -36 c0 -10 -4 -18 -10 -18 -15 0 -43 48 -35 60 10 16 45 -17 45 -42z
m164 36 c20 -20 20 -43 1 -50 -21 -9 -45 14 -45 42 0 28 21 32 44 8z m294 -25
c4 -19 -19 -31 -38 -19 -18 11 -4 42 17 38 10 -2 19 -10 21 -19z m172 -10 c0
-5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m-282 -17 c3 -20 0 -23
-20 -20 -15 2 -24 11 -26 26 -3 20 0 23 20 20 15 -2 24 -11 26 -26z m-108 3
c15 -18 6 -45 -13 -45 -20 0 -40 27 -33 45 7 19 30 19 46 0z m-96 -14 c21 -23
12 -45 -16 -38 -18 5 -19 4 -4 -12 18 -19 21 -41 7 -41 -5 0 -7 -10 -3 -22 4
-16 2 -19 -9 -14 -10 6 -10 4 1 -6 8 -7 21 -24 28 -37 l13 -23 -29 17 c-33 20
-59 55 -41 55 8 0 7 6 -2 17 -11 12 -11 17 -2 20 10 4 10 9 0 24 -10 16 -9 21
2 28 7 5 10 14 7 20 -9 14 1 31 18 31 7 0 21 -9 30 -19z m384 2 c5 -18 -28
-29 -38 -12 -5 7 -6 17 -3 22 7 12 35 5 41 -10z m152 -3 c0 -5 -5 -10 -11 -10
-5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-263 -16 c3 -8 1 -20 -4 -25
-12 -12 -48 14 -39 29 10 17 36 15 43 -4z m-102 -28 c0 -26 -19 -31 -39 -11
-20 21 -11 37 17 33 14 -2 22 -10 22 -22z m263 13 c-2 -6 -8 -10 -13 -10 -5 0
-11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-358 -18 c7 -13 7 -22 0 -26
-18 -11 -50 6 -50 26 0 25 36 25 50 0z m266 -2 c9 -16 -18 -30 -33 -17 -7 6
-10 14 -7 19 7 13 31 11 40 -2z m144 -9 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
3 6 8 10 11 10 2 0 4 -4 4 -10z m-245 -20 c0 -22 -39 -19 -43 3 -3 15 1 18 20
15 12 -2 23 -10 23 -18z m-97 -2 c20 -20 14 -30 -15 -26 -32 4 -46 38 -16 38
11 0 24 -5 31 -12z m252 -3 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23
8 23 -8z m-342 -7 c17 -17 15 -28 -4 -28 -14 0 -14 -3 -4 -15 7 -9 10 -18 7
-22 -4 -3 -2 -12 4 -20 6 -7 8 -13 3 -13 -5 0 -2 -4 6 -10 8 -5 12 -11 10 -14
-8 -7 -51 28 -53 44 -2 8 -6 21 -11 29 -5 8 -4 16 4 21 9 5 9 9 1 14 -6 4 -11
11 -11 17 0 13 34 11 48 -3z m250 -16 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14
24 9 9 26 2 31 -12z m142 -2 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10
11 10 2 0 4 -4 4 -10z m-230 -15 c0 -8 -9 -15 -19 -15 -10 0 -21 7 -25 15 -4
11 1 15 19 15 16 0 25 -6 25 -15z m-84 -6 c14 -23 -13 -30 -37 -10 l-24 20 27
1 c15 0 30 -5 34 -11z m226 -4 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5
15 11 13 6 -2 11 -8 11 -13z m-81 -20 c-1 -15 -24 -12 -29 3 -3 9 2 13 12 10
10 -1 17 -7 17 -13z m-82 -4 c9 -6 10 -10 2 -16 -15 -8 -41 1 -41 15 0 12 20
13 39 1z m-74 -31 c0 -14 6 -28 15 -32 13 -6 13 -7 -2 -7 -21 -1 -56 31 -39
37 8 3 6 9 -6 18 -17 14 -16 14 7 12 19 -2 25 -9 25 -28z m206 17 c-1 -12 -15
-9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m-142 -38 c-11 -7 -11 -9 -1 -9 7
0 10 -5 6 -11 -8 -14 -27 4 -31 31 -3 18 0 19 18 9 17 -9 19 -13 8 -20z m76
21 c3 -5 -1 -10 -9 -10 -8 0 -18 5 -21 10 -3 6 1 10 9 10 8 0 18 -4 21 -10z
m120 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-68 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-64 -11 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m4 -19 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M657 1668 c-9 -31 -2 -33 11 -3 6 14 8 25 3 25 -5 0 -11 -10 -14 -22z"/>
<path d="M622 1420 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1796 1424 c-16 -41 -3 -94 23 -94 25 0 41 20 41 51 0 42 -10 59 -35
59 -13 0 -26 -7 -29 -16z"/>
<path d="M2805 1429 c-4 -6 -7 -25 -7 -44 0 -41 8 -55 32 -55 20 0 30 19 30
55 0 13 -3 30 -6 39 -7 18 -39 21 -49 5z"/>
<path d="M2022 1428 c-13 -13 -16 -56 -6 -82 9 -25 43 -19 56 9 22 48 -17 106
-50 73z"/>
<path d="M3312 1428 c-17 -17 -15 -73 3 -88 22 -18 48 -8 59 22 19 53 -27 101
-62 66z"/>
<path d="M487 809 c-52 -28 -77 -71 -77 -138 0 -67 27 -114 81 -141 38 -20 66
-20 1636 -20 1536 0 1600 1 1639 19 56 25 84 73 84 142 0 67 -25 111 -79 139
-40 20 -49 20 -1643 20 -1584 -1 -1603 -1 -1641 -21z m90 -91 c49 -46 14 -118
-57 -118 l-40 0 0 70 0 70 37 0 c24 0 45 -8 60 -22z m146 -45 c21 -58 23 -68
9 -72 -8 -1 -19 4 -24 13 -12 21 -44 21 -56 0 -5 -9 -16 -14 -24 -13 -24 6 27
139 52 139 13 0 25 -19 43 -67z m141 45 c7 -12 6 -23 -3 -41 -11 -20 -11 -28
0 -46 8 -11 17 -21 21 -21 3 0 16 30 28 66 18 54 25 65 42 62 15 -2 27 -20 44
-68 13 -36 24 -66 24 -67 0 -2 -9 -3 -20 -3 -11 0 -20 7 -20 15 0 9 -9 15 -25
15 -14 0 -28 -7 -31 -15 -4 -10 -20 -15 -45 -15 -33 0 -41 4 -56 33 l-18 32
-3 -32 c-2 -21 -9 -33 -18 -33 -11 0 -14 16 -14 71 l0 70 41 -3 c26 -2 46 -10
53 -20z m233 -25 l20 -48 22 48 c15 33 28 47 41 47 18 0 20 -7 20 -70 0 -63
-2 -70 -20 -70 -15 0 -20 7 -20 28 l-1 27 -15 -27 c-19 -37 -37 -35 -57 5
l-16 32 0 -32 c-1 -22 -6 -33 -16 -33 -12 0 -15 14 -15 70 0 61 2 70 18 70 12
0 25 -16 39 -47z m163 -23 c0 -56 -3 -70 -15 -70 -12 0 -15 14 -15 70 0 56 3
70 15 70 12 0 15 -14 15 -70z m70 48 l0 -21 23 21 c13 12 34 22 47 22 24 0 24
0 -7 -32 l-32 -33 32 -35 c28 -31 29 -35 12 -39 -12 -2 -29 9 -47 30 l-27 34
-1 -32 c0 -23 -5 -33 -15 -33 -12 0 -15 14 -15 70 0 56 3 70 15 70 9 0 15 -9
15 -22z m200 12 c0 -5 -13 -10 -30 -10 -23 0 -30 -4 -30 -20 0 -15 7 -20 25
-20 14 0 25 -4 25 -10 0 -5 -11 -10 -25 -10 -18 0 -25 -5 -25 -20 0 -16 7 -20
30 -20 17 0 30 -4 30 -10 0 -5 -20 -10 -45 -10 l-45 0 0 70 0 70 45 0 c25 0
45 -4 45 -10z m225 -3 c24 -18 11 -41 -15 -27 -48 26 -92 -2 -72 -45 13 -29
52 -34 72 -10 11 13 8 15 -14 15 -14 0 -26 5 -26 10 0 6 18 10 40 10 33 0 40
-3 40 -20 0 -58 -91 -82 -129 -34 -27 34 -27 62 1 91 24 26 74 31 103 10z m95
-47 c0 -57 1 -60 25 -60 14 0 25 -4 25 -10 0 -5 -20 -10 -45 -10 l-45 0 0 70
c0 63 2 70 20 70 18 0 20 -7 20 -60z m190 40 c44 -44 11 -120 -52 -120 -39 0
-78 34 -78 69 0 61 86 95 130 51z m148 8 c5 -7 9 -38 8 -68 l-1 -55 -52 -3
-53 -3 0 70 0 71 44 0 c24 0 48 -6 54 -12z m138 -58 c13 -36 24 -66 24 -67 0
-2 -9 -3 -20 -3 -11 0 -20 7 -20 15 0 9 -9 15 -25 15 -14 0 -28 -7 -31 -15 -4
-8 -14 -15 -24 -15 -16 0 -15 6 8 70 33 93 54 93 88 0z m74 10 c0 -57 1 -60
25 -60 14 0 25 -4 25 -10 0 -5 -18 -10 -40 -10 l-40 0 0 70 c0 56 3 70 15 70
12 0 15 -13 15 -60z m240 39 c9 -16 8 -26 -4 -43 -13 -21 -13 -26 -1 -42 19
-25 19 -34 0 -34 -9 0 -24 13 -33 30 -22 38 -32 38 -32 0 0 -20 -5 -30 -15
-30 -12 0 -15 14 -15 70 l0 70 45 0 c35 0 47 -5 55 -21z m130 11 c0 -5 -13
-10 -30 -10 -23 0 -30 -4 -30 -20 0 -15 7 -20 25 -20 14 0 25 -4 25 -10 0 -5
-11 -10 -25 -10 -18 0 -25 -5 -25 -20 0 -16 7 -20 30 -20 17 0 30 -4 30 -10 0
-5 -20 -10 -45 -10 l-45 0 0 70 0 70 45 0 c25 0 45 -4 45 -10z m108 -8 c3 -9
-3 -13 -18 -10 -34 5 -34 -15 0 -34 33 -19 42 -61 14 -72 -27 -10 -61 -7 -73
8 -16 19 3 30 28 16 13 -7 22 -7 26 0 8 13 -3 30 -20 30 -24 0 -46 40 -34 62
12 22 70 22 77 0z m167 -7 c14 -13 25 -33 25 -45 0 -32 -44 -70 -80 -70 -44 0
-84 42 -76 78 15 61 87 82 131 37z m85 -29 c0 -57 7 -70 34 -60 12 5 16 20 16
60 0 47 2 54 20 54 17 0 20 -7 20 -48 0 -63 -21 -92 -65 -92 -44 0 -65 29 -65
92 0 41 3 48 20 48 18 0 20 -7 20 -54z m220 33 c9 -16 8 -26 -4 -43 -13 -21
-13 -26 -1 -42 35 -46 1 -47 -37 -2 l-27 33 -1 -32 c0 -23 -5 -33 -15 -33 -12
0 -15 14 -15 70 l0 70 45 0 c35 0 47 -5 55 -21z m155 5 c21 -21 4 -44 -19 -25
-20 16 -56 10 -70 -11 -23 -37 35 -76 70 -47 23 19 40 -4 19 -25 -23 -23 -80
-21 -105 4 -45 45 -11 120 54 120 19 0 42 -7 51 -16z m135 6 c0 -5 -13 -10
-30 -10 -23 0 -30 -4 -30 -20 0 -15 7 -20 25 -20 14 0 25 -4 25 -10 0 -5 -11
-10 -25 -10 -18 0 -25 -5 -25 -20 0 -16 7 -20 30 -20 17 0 30 -4 30 -10 0 -5
-20 -10 -45 -10 l-45 0 0 70 0 70 45 0 c25 0 45 -4 45 -10z m118 -8 c3 -10 -4
-13 -21 -10 -36 4 -36 -13 -1 -33 33 -18 42 -43 24 -64 -21 -25 -90 -16 -90
11 0 17 22 18 38 2 9 -9 15 -9 23 -1 9 9 3 17 -25 33 -37 23 -45 47 -24 68 16
16 70 11 76 -6z"/>
<path d="M510 670 c0 -54 4 -58 38 -34 27 18 28 45 4 67 -32 29 -42 21 -42
-33z"/>
<path d="M670 665 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M800 700 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18 -13
2 -18 -3 -18 -18z"/>
<path d="M945 677 c-8 -19 -2 -31 13 -26 5 2 6 13 2 24 -7 19 -8 19 -15 2z"/>
<path d="M1950 695 c-21 -25 3 -67 37 -63 34 4 52 31 37 56 -15 23 -57 27 -74
7z"/>
<path d="M2130 700 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18 -13
2 -18 -3 -18 -18z"/>
<path d="M2130 640 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18 -13
2 -18 -3 -18 -18z"/>
<path d="M2275 677 c-8 -19 -2 -31 13 -26 5 2 6 13 2 24 -7 19 -8 19 -15 2z"/>
<path d="M2570 700 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18 -13
2 -18 -3 -18 -18z"/>
<path d="M2954 696 c-3 -8 -4 -25 -2 -38 2 -19 9 -23 38 -23 33 0 35 2 35 35
0 32 -3 35 -33 38 -21 2 -34 -2 -38 -12z"/>
<path d="M3280 700 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18 -13
2 -18 -3 -18 -18z"/>
</g>
</svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}